import React, { useState } from "react";
import { useTable, useFilters, useSortBy } from "react-table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function Table({ columns, data, hidden, title, type }) {
  const [filterInput, setFilterInput] = useState("");
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: [hidden]
      }
    },
    useFilters,
    useSortBy
  );

  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setFilter("name", value);
    setFilterInput(value);
  };

  // Render the UI for your table
  return (
    <div className="flex flex-col w-full bg-gray-200">
      {title && <h1
        className="ml-3 sm:ml-0 md:ml-0 xl:ml-0 text-2xl my-5 tracking-tight sm:text-4xl md:text-3xl xl:text-4xl font-medium leading-tight"
        >
        {title}
        </h1>}
      <div className="hidden md:block relative">
        <input
          className="w-full md:w-1/3 pl-10 pr-4 py-2 mb-5 border focus:outline-none focus:shadow-outline text-gray-600 font-medium"
          value={filterInput}
          onChange={handleFilterChange}
          placeholder={"Søk etter lagnavn"}
        />
        <div className="absolute top-0 left-0 inline-flex items-center p-2">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
            strokeWidth="2" stroke="currentColor" fill="none" stroke-linecap="round"
            strokeLinejoin="round">
            <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
            <circle cx="10" cy="10" r="7" />
            <line x1="21" y1="21" x2="15" y2="15" />
          </svg>
        </div>
      </div>
      {
        <div className="md:hidden">
          {data && type === "scores" && data.map(it => (
            <div className="bg-white flex flex-col border p-2">
              <div className="w-full flex flex-row">
                <div className="flex flex-row pr-5">
                  Runde {it.round}
                </div>
                <div className="flex flex-row">
                  {new Date(it.timestamp).toLocaleDateString("no-NO")}
                </div>
              </div>
              <div className="w-full flex flex-row">
                <div className="flex flex-row">
                  <div className="pr-5">
                    {it.homeTeam.name}
                  </div>
                  <div className="font-bold">
                    {it.result.homeScore90}
                  </div>
                </div>
                <div className="font-bold px-1">
                -
                </div>
                <div className="flex flex-row">
                  <div className="pr-5 font-bold">
                    {it.result.awayScore90}
                  </div>
                  <div>
                    {it.awayTeam.name}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      }
      {
        <div className="md:hidden">
          {data && type === "table" && data.map(it => (
            <div className="bg-white border flex flex-row justify-between">
            <div className="flex flex-row p-2">
              <img className="h-6 w-6 fill-current mr-2" src={it.logo.url}></img>
              {it.place}. {it.name}
            </div>
            <div className="flex flex-col pr-3">
                <div>
                  Poeng: {it.points}
                </div>
                <div>
                  Kamper: {it.played}
                </div>
              </div>
            </div>
          ))}
        </div>
      }
      {!data.length ?
        <SkeletonTheme color="#ffffff" activeColor="#f9f9f9">
        <Skeleton height={50} count={10}/>
        </SkeletonTheme>
        :
      <div className="hidden md:block overflow-x-auto bg-white border overflow-y-auto relative">
        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr className="text-left" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      "bg-gray-100 sticky top-0 border-b border-gray-200 md:px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs overflow-hidden"
                      + " " +
                      (column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc arrow-thin-up"
                          : "sort-asc"
                        : "")
                    }
                  >
                    {column.render("Header")}
                    {column.isSorted && (column.isSortedDesc
                      ? <FontAwesomeIcon className="w-6 ml-2 fill-current" icon={faSortUp} />
                      : <FontAwesomeIcon className="w-6 ml-2 fill-current" icon={faSortDown} />)

                    }
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>

            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td className="text-center border-dashed border-t border-gray-200" {...cell.getCellProps()}>
                        <span className="text-center text-gray-700 md:px-6 py-3 flex items-center">
                          {cell.render("Cell")}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>}
    </div>
  );
}
